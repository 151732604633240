
import { Component } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		audio: HTMLAudioElement
		audioScaned: HTMLAudioElement
	}
	private isWechat = false

	private isLoading = false
	private shelfPackagesList: any = []
	private total = 0
	private searchForm: any = {
		shelf_code: ''
	}
	private validateForm = {
		shelf_code: [{ required: true, message: '请扫描货架', trigger: 'blur' }]
	}
	async created() {
		this.isWechat = isWechat()
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					const code = result[result.length - 1]
					this.searchForm.shelf_code = code
					this.searchShelfPackages()
				}
			})
		})
	}
	async searchShelfPackages() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return false
		const { shelf_code } = this.searchForm
		if (!shelf_code) return
		const { data } = await this.$axios
			.get(`/v1/jobline/shelf-package/${shelf_code}`)
			.catch(e => {
				this.searchForm.shelf_code = ''
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (!data) return
		console.log(data)
		this.shelfPackagesList = data.list
		this.total = data.total
		this.searchForm.shelf_code = ''
	}
}
