import { render, staticRenderFns } from "./search.vue?vue&type=template&id=b6c7c1da&scoped=true"
import script from "./search.vue?vue&type=script&lang=tsx"
export * from "./search.vue?vue&type=script&lang=tsx"
import style0 from "./search.vue?vue&type=style&index=0&id=b6c7c1da&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c7c1da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend_page_elephant/frontend_page_elephant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6c7c1da')) {
      api.createRecord('b6c7c1da', component.options)
    } else {
      api.reload('b6c7c1da', component.options)
    }
    module.hot.accept("./search.vue?vue&type=template&id=b6c7c1da&scoped=true", function () {
      api.rerender('b6c7c1da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/warehouse/shelf/search.vue"
export default component.exports